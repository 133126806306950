<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      :ref="modal_name">
    <v-card flat :loading="loading" :disabled="loading">
      <BaseModal>
        <v-toolbar class="draggable_selector" dark color="primary" slot="header" dense flat>
          <v-btn icon @click.stop="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ page.title }}</v-toolbar-title>
          <v-spacer/>
          <v-btn text @click.stop="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-stepper slot="content" v-model="e6" vertical>
          <v-stepper-step editable :complete="e6 > 1" step="1">
            {{ $store.getters.translate("choose_person_type") }}
          </v-stepper-step>
          <v-stepper-content step="1">
            <v-card flat class="ml-5">
              <v-radio-group v-model="record.type" :mandatory="true" row>
                <v-radio value="candidate" :label="$store.getters.translate('candidate')"/>
                <v-radio value="assessor" :label="$store.getters.translate('assessor')"/>
                <v-radio value="employee" :label="$store.getters.translate('employee')"/>
                <v-radio value="contact" :label="$store.getters.translate('contact')"/>
              </v-radio-group>
            </v-card>
          </v-stepper-content>
          <v-stepper-step editable :complete="e6 > 2" step="2">
            {{ $store.getters.translate("add_person_contact_info") }}
          </v-stepper-step>
          <v-stepper-content step="2">
            <v-card flat class="ml-5">
              <template v-if="stored_people.length > 0">
                <v-alert v-for="(person, index) in stored_people" :key="index" dense outlined type="warning">
                  {{ $store.getters.translate("person_already_exists") }}
                  <br />
                  <a @click="$router.push(getRelationType(person) + 's/' + getRelationId(person))">
                    <small>{{ person.name }} ({{ person[getRelationType(person)].id }})</small>
                  </a>
                </v-alert>
              </template>
              <template v-for="field in usual_fields">
                <template v-if="field.name === 'name'">
                  <v-text-field
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      :key="field.name + '_first_name'"
                      :label="$store.getters.translate('first_name')"
                      v-model="record.first_name"
                      required
                      validate-on-blur
                      :hint="$store.getters.translate('required')"
                      @blur="checkExistence">
                    <template #label>{{ $store.getters.translate('first_name') }}<span class="red--text"><strong>*</strong></span></template>
                  </v-text-field>
                  <v-text-field
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      :key="field.name + '_insertion'"
                      :label="$store.getters.translate('insertion')"
                      v-model="record.insertion"/>
                  <v-text-field
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      :key="field.name + '_last_name'"
                      :label="$store.getters.translate('last_name')"
                      v-model="record.last_name"
                      required
                      validate-on-blur
                      :hint="$store.getters.translate('required')"
                      @blur="checkExistence">
                    <template #label>{{ $store.getters.translate('last_name') }}<span class="red--text"><strong>*</strong></span></template>
                  </v-text-field>
                  <v-text-field
                      :class="'ma-0 pt-'+$store.getters.lineheight"
                      :key="field.name + '_initials'"
                      :label="$store.getters.translate('initials')"
                      v-model="record.initials"/>
                </template>
                <template v-else-if="field.name === 'can_view_certificates' || field.name === 'newsletter' || field.name === 'jobtitle' || field.name === 'reference' ||
                field.name === 'is_dyslexic' || field.name === 'is_dyscalc' || field.name === 'btw_number' || field.name === 'preferred_certificate_language' || field.name === 'type'">
                  <base-field
                      :key="field.name"
                      :value="record[user_type][field.name]"
                      :field="field"
                      @change="usualFieldDataChanged"/>
                </template>
                <template v-else>
                  <base-field
                      :key="field.name"
                      :value="record[field.name]"
                      :field="field"
                      @change="usualFieldDataChanged"/>
                </template>
              </template>
              <base-addresses v-model="record.addresses" :is_submit="is_submit" address_type="visiting" label="contact_address" mapID="map1"/>
              <template v-if="record.type === 'candidate'">
                <v-switch :label="$store.getters.translate('billing_address_the_same')" v-model="hide_billing_address"/>
                <base-addresses v-if="!hide_billing_address" v-model="record.addresses" :is_submit="is_submit" address_type="billing" label="billing_address" mapID="map2"/>
              </template>
              <email-addresses v-model="record.emailaddresses" email_type="personal" label="emailaddress" @email_deleted="email_deleted" :is_submit="is_submit"/>
              <v-switch
                  v-if="record.type !== 'contact'"
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  :disabled="record.emailaddresses.length === 0"
                  v-model="record.notify"
                  :label="$store.getters.translate('send_account_create_notification')"/>
              <v-textarea
                  v-if="record.notify"
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  v-model="record.message"
                  :label="$store.getters.translate('message')"/>
            </v-card>
          </v-stepper-content>
          <v-stepper-step editable :complete="e6 > 3" step="3">
            {{ $store.getters.translate("attach_to_companies") }}
          </v-stepper-step>
          <v-stepper-content step="3">
            <v-card flat class="ml-5">
              <v-select
                  :class="'ma-0 pt-'+$store.getters.lineheight"
                  v-model="record.add_to_companies"
                  :items="companies"
                  chips
                  deletable-chips
                  small-chips
                  multiple
                  item-value="id"
                  item-text="name"/>
            </v-card>
          </v-stepper-content>
          <v-stepper-step v-if="tags_required" editable :complete="e6 > 4" step="4">
            {{ $store.getters.translate('tags') }}
          </v-stepper-step>
          <v-stepper-content v-if="tags_required" step="4">
            <v-card flat class="ml-5">
              <base-tags v-model="record.tags" :module_name="user_type + 's'"/>
            </v-card>
          </v-stepper-content>
          <v-stepper-step v-if="custom_fields.length > 0" editable :complete="e6 > 5" step="5">
            {{ $store.getters.translate('additional_information') }}
          </v-stepper-step>
          <v-stepper-content v-if="custom_fields.length > 0" step="5">
            <v-card flat class="ml-5">
              <template v-for="field in custom_fields">
                <base-field
                    :key="field.name"
                    :value="record[user_type].custom_fields[field.name]"
                    :field="field"
                    @change="customFieldDataChanged"/>
              </template>
            </v-card>
          </v-stepper-content>
        </v-stepper>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import BaseAddresses from "../../components/commonComponents/BaseAddresses";
import EmailAddresses from "../../components/commonComponents/BaseEmailAddresses";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import helpFunctions from "../../plugins/helpFunctions";

export default {
  props: ["module_id", "action", "parent", "modal_name", "user_type"],
  components: {
    BaseAddresses,
    EmailAddresses,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("create_person"),
        name: "people",
      },
      e6: 1,
      stored_people: [],
      record: {
        type: "",
        notify: false,
        initials: "",
        first_name: "",
        insertion: "",
        last_name: "",
        date_of_birth: "",
        phonenumber: "",
        mobilenumber: "",
        billingnumber: "",
        gender: "",
        country_of_birth_id: "",
        place_of_birth: "",
        nationality_id: "",
        contact: {
          can_view_certificates: true,
          newsletter: false,
          custom_fields: {}
        },
        employee: {
          jobtitle: "",
          custom_fields: {}
        },
        candidate: {
          is_dyslexic: false,
          is_dyscalc: false,
          btw_number: null,
          reference: "",
          preferred_certificate_language: "nl",
          email: "",
          custom_fields: {}
        },
        assessor: {
          type: [],
          custom_fields: {}
        },
        add_to_companies: [],
        addresses: [],
        emailaddresses: [],
      },
      hide_billing_address: false,
      loading: false,
      valid: false,
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      countries: [],
      nationalities: [],
      companies: [],
      is_submit: false,
      isExistPerson: false
    };
  },
  created() {
    this.record.type = this.user_type;
    this.getCompanies();
    this.getCountryNationality();
  },
  methods: {
    ...helpFunctions.modal_functions,
    email_deleted() {
      this.record.emailaddresses = [];
      this.record.notify = false;
    },
    getRelationId(person) {
      if (person.candidate) {
        return person.candidate.id;
      }
      if (person.contact) {
        return person.contact.id;
      }
      if (person.assessor) {
        return person.assessor.id;
      }
    },
    getRelationType(person) {
      if (person.candidate) {
        return "candidate";
      }
      if (person.contact) {
        return "contact";
      }
      if (person.assessor) {
        return "assessor";
      }
      if (person.employee) {
        return "employee";
      }
    },
    save() {
      if(!this.loading) {
        let error = false;
        if (this.record.first_name === '' || this.record.last_name === '') {
          this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('name'));
          error = true;
        }
        this.usual_fields.forEach((field) => {
          if (field.mandatory && field.field_type !== 'boolean') {
            if (field.name === 'can_view_certificates' || field.name === 'newsletter' || field.name === 'jobtitle' || field.name === 'reference' || field.name === 'preferred_certificate_language' || field.name === 'type') {
              if (!this.record[this.user_type][field.name] || this.record[this.user_type][field.name] === '') {
                this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
                error = true;
              }
            } else if (field.name !== 'name' && field.name !== 'is_dyslexic' && field.name !== 'is_dyscalc' && field.name !== 'btw_number') {
              if (!this.record[field.name] || this.record[field.name] === '') {
                this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
                error = true;
              }
            }
          }
        });
        if (!error) {
          if (this.record.phonenumber && this.record.phonenumber.charAt(0) !== '+') {
            this.$toasted.error(this.$store.getters.translate("international_number_error"));
          } else if (this.record.mobilenumber && this.record.mobilenumber.charAt(0) !== '+') {
            this.$toasted.error(this.$store.getters.translate("international_number_error"));
          } else if (this.record.billingnumber && this.record.billingnumber.charAt(0) !== '+') {
            this.$toasted.error(this.$store.getters.translate("international_number_error"));
          } else {
            this.loading = true;
            this.$http
                .post(this.$store.getters.appUrl + "v2/people", this.record)
                .then((response) => {
                  this.loading = false;
                  if (response.status == 200) {
                    if (this.action === "create_contact" && this.module_id) {
                      this.attachContactToCandidate(this.module_id, response.data.contact);
                    } else {
                      this.$toasted.success(this.$store.getters.translate("successfully_saved"));
                      this.$emit("refresh");
                      this.is_submit = !this.is_submit;
                      this.reset();
                    }
                  } else {
                    var errorMessage = response.data.message;
                    var errors = Object.keys(response.data.message);
                    this.$toasted.error([errorMessage[errors[0]]]);
                  }
                })
                .catch((error) => {
                  this.$toasted.error(error);
                  this.loading = false;
                });
          }
        }
      }
    },
    attachContactToCandidate(candidate_id, contact) {
      this.loading = true;
      let attach_data = {
        attach: [contact.id],
        model: "candidate",
        model_id: candidate_id,
        objects: [contact],
        relation_name: "contacts",
      };
      this.$http
        .post(this.$store.getters.appUrl + "v2/bulk/attach", attach_data)
        .then((response) => {
          this.loading = false;
          if (response.status == 200) {
            this.$toasted.success(this.$store.getters.translate("successfully_saved"));
            this.$emit("refresh");
            this.is_submit = !this.is_submit;
            this.reset();
          } else {
            var errorMessage = response.data.message;
            var errors = Object.keys(response.data.message);
            this.$toasted.error([errorMessage[errors[0]]]);
          }
        })
        .catch((error) => {
          this.$toasted.error(error);
          this.loading = false;
        });
    },
    checkExistence() {
      if (this.record.first_name && this.record.last_name) {
        this.$http
          .post(this.$store.getters.appUrl + "v2/people/checkexistence", {
            first_name: this.record.first_name,
            last_name: this.record.last_name,
          })
          .then((response) => {
            response.data.forEach((person) => {
              if (this.record.type === this.getRelationType(person)) {
                this.stored_people.push(person);
              }
            });
          })
          .catch((response, error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
      } else {
        this.isExistPerson = false;
      }
    },
    usualFieldDataChanged(data) {
      if(data[0] === 'can_view_certificates' || data[0] === 'newsletter' || data[0] === 'jobtitle' || data[0] === 'reference' || data[0] === 'is_dyslexic' ||
          data[0] === 'is_dyscalc' || data[0] === 'btw_number' || data[0] === 'preferred_certificate_language' || data[0] === 'type') {
        this.record[this.user_type][data[0]] = data[1];
      }
      else {
        this.record[data[0]] = data[1];
      }
    },
    customFieldDataChanged(data) {
      this.record[this.user_type].custom_fields[data[0]] = data[1];
    },
    async getFields() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/fields?source=site&show_on_creation=true&model_type=" + this.user_type)
          .then((response) => {
            this.custom_fields = [];
            this.usual_fields = [];
            this.tags_required = false;
            response.data.data.forEach((field) => {
              if (field.custom_field == 1) {
                this.custom_fields.push(field);
              }
              else if(field.name === 'tags') {
                this.tags_required = true;
              }
              else {
                if(field.name === 'country_of_birth_id') {
                  field.available_options = this.countries;
                }
                else if(field.name === 'nationality_id') {
                  field.available_options = this.nationalities;
                }
                else if(field.field_type === 'select_single' || field.field_type === 'select_multiple') {
                  for (let i = 0; i < field.available_options.length; i++) {
                    field.available_options[i] = {
                      text: this.$store.getters.translate(field.available_options[i]),
                      value: field.available_options[i],
                    };
                  }
                }
                this.usual_fields.push(field);
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    getCompanies() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/companies")
          .then((response) => {
            this.companies = this.$lodash.sortBy(response.data.data, "name");
            this.loading = false;
          })
          .catch((error) => {
            this.$toasted.error(error);
            this.loading = false;
          });
    },
    getCountryNationality() {
      this.$http
          .get(this.$store.getters.appUrl + "v2/countries")
          .then((response) => {
            this.countries = [];
            response.data.country.forEach((option) => {
              this.countries.push({
                value: option.country_id,
                text: option.country_name,
              });
            });
            this.nationalities = [];
            response.data.nationality.forEach((option) => {
              this.nationalities.push({
                value: option.nationality_id,
                text: option.nationality_name,
              });
            });
            if(this.user_type !== "") {
              this.getFields();
            }
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
          });
    },
    reset() {
      this.e6 = 1;
      this.isExistPerson = false;
      this.record = {
        type: "",
        notify: false,
        initials: "",
        first_name: "",
        insertion: "",
        last_name: "",
        date_of_birth: "",
        phonenumber: "",
        mobilenumber: "",
        billingnumber: "",
        gender: "",
        country_of_birth_id: "",
        place_of_birth: "",
        nationality_id: "",
        contact: {
          can_view_certificates: true,
          newsletter: false,
          custom_fields: {}
        },
        employee: {
          jobtitle: "",
          custom_fields: {}
        },
        candidate: {
          is_dyslexic: false,
          is_dyscalc: false,
          btw_number: null,
          reference: "",
          preferred_certificate_language: "nl",
          email: "",
          custom_fields: {}
        },
        assessor: {
          type: [],
          custom_fields: {}
        },
        add_to_companies: [],
        addresses: [],
        emailaddresses: [],
      };
      this.record.type = this.user_type;
      this.closeModal(this.modal_name);
    },
  },
  watch: {
    hide_billing_address: {
      handler() {
        if(this.user_type === 'candidate') {
          if (this.hide_billing_address) {
            this.record.addresses.forEach((address) => {
              if (address.type === 'billing') {
                this.record.addresses = this.record.addresses.filter((address2) => address2.formatted_address !== address.formatted_address);
              } else if (address.type === 'visiting' && !address.primary) {
                address.primary = 1;
              }
            });
          } else {
            this.record.addresses.forEach((address) => {
              if (address.type === 'visiting' && address.primary) {
                address.primary = 0;
              }
            });
          }
        }
      },
    },
  },
};
</script>